<template>
  <div :class="{'dropdown-error': fieldError}" class="dropdown-wrapper">
    <!-- The title of the field -->
    <div v-if="fieldTitle" class="dropdown-title-box">
      <p class="dropdown-title form-input-title">{{ fieldTitle }}</p>
    </div>
    <p v-if="fieldText" class="field-text">{{ fieldText }}</p>

    <div :id="`dropdown-toggle-${id}`" class="basic-dropdown" :class="{'disabled': disabled}" @click="toggleDropdown()">

      <!-- The dropdown input -->
      <!-- TODO: Modify alt texts either with Dutch texts or whatever suits best here.. -->
      <div class="dropdown-text-container">
        <InlineSvg :src="require('../../assets/icons/icn_arrow.svg')" class="dropdown-icon" :class="{'foldout': isDropdownShown}" />
        <InputField :field-title="''"
                    :field-name="fieldName"
                    :rules="'required'"
                    :type="'text'"
                    :is-read-only="true"
                    :placeholder="placeholder"
                    :value="selected ? selected.name : ''"
                    :input-icon="inputIcon"
                    :input-icon-error="inputIconError"
                    :has-input-icon-error="hasInputIconError"
                    :debounce="0"
                    :api-error-text="apiErrorText"
                    error-icon-offset="2rem">
          <template #right-side-content>
            <slot name="right-side-content"/>
          </template>
        </InputField>
      </div>

      <!-- The dropdown list -->
      <div v-if="isDropdownShown" :id="`option-list-${id}`" class="option-list">
        <div class="option-list-inner">
          <div v-for="option in dropdownOptions"
               :id="`option-${option.id}`"
               :key="option.id"
               :value="option.id"
               :class="{'selected-option': selected ? option.id === selected.id : false}"
               class="option"
               @click="selectOption(option)">
            {{ option.name }}
            <img v-if="selected ? option.id === selected.id : false && hasSelectedCheckmark"
              class="selected-checkmark"
              :src="require('../../assets/icons/icn_accept.svg')" 
              alt="Checkmark"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from '@/components/yo-form/InputField'
import { ref, watch, onUnmounted } from 'vue'
import { addListener, removeListener } from '@/utils/helpers/listenerHelper'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'Dropdown',
  components: {
    InputField,
    InlineSvg
  },
  props: {
    dropdownOptions: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    fieldTitle: {
      type: String,
      required: false,
      default: undefined
    },
    fieldText: {
      type: String,
      required: false,
      default: undefined
    },
    fieldName: {
      type: String,
      required: true,
    },
    selectedOption: {
      type: Object,
      default: null
    },
    callback: {
      type: Function,
      required: true
    },
    fieldError: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      required: false,
      default: 'default'
    },
    inputIcon: {
      type: String,
      required: false,
      default: ''
    },
    inputIconError: {
      type: String,
      required: false,
      default: ''
    },
    hasInputIconError: {
      type: Boolean,
      required: false,
      default: true
    },
    apiErrorText: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hasSelectedCheckmark: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['toggled', 'selected'],
  setup(props, { emit }) {
    const isDropdownShown = ref(false)
    const selected = ref(undefined)
    setValues()

    /** On initialization **/
    function setValues() {
      if (!props.selectedOption) {
        props.callback(selected.value)
      } else {
        selected.value = props.selectedOption
      }
    }
    // If there is a new value passed from the parent, the dropdown should display that new value.
    watch(() => props.selectedOption, (newValue, prevValue) => {
      if (newValue) {
        selected.value = newValue
      }
    })

    /** Toggling **/
    function toggleDropdown() {
      if (props.disabled) {
        return
      }
      isDropdownShown.value = !isDropdownShown.value
      emit('toggled', isDropdownShown.value)
      // If the dropdown option list is displayed, the click event should be listened to.
      isDropdownShown.value ? addListener('click', clickEvent) : removeListener('click', clickEvent)
    }

    /** On option click **/
    const clickEvent = (event) => {
      if (!eventContainsElement(event, `option-list-${props.id}`) && !eventContainsElement(event, `dropdown-toggle-${props.id}`)) {
        toggleDropdown()
      }
    }

    function selectOption(option) {
      selected.value = option
      emit('selected', option);
      props.callback(option)
    }

    /** Other helper functions **/
    function eventContainsElement(event, elementId) {
      return document.getElementById(elementId).contains(event.target)
    }

    /** On exit **/
    // When the component is unmounted, the listeners should be removed.
    onUnmounted(() => {
      removeListener('click', clickEvent)
    })

    return {
      isDropdownShown,
      selected,
      /** Toggling **/
      toggleDropdown,

      /** On option click **/
      selectOption,
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";

$background-color: white;
$border-color: #F4F6FB;
$text-color: var(--blue_dark_01);

.basic-dropdown {
  position: relative;

  :deep {
    input {
      cursor: pointer;
    }
  }

  &.disabled {

    :deep {
      input {
        cursor: not-allowed;
      }
    }
  }

  .dropdown-text-container {
    position: relative;
    align-items: center;
    display: flex;
    height: 100%;
  }

  .dropdown-text {
    font-size: 1em;
    opacity: 0.4;
    padding: 0;
  }

  .dropdown-text-error {
    color: var(--red_error);
    opacity: 1;
  }

  .dropdown-selected-text {
    font-size: 1em;
  }

  .option-list {
    @include position-elevation(absolute, $top: 100%, $right: 0, $left: 0, $elevation: 15);
    border-radius: rem(10);
    background-color: #FFFFFF;
    box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);

    .option-list-inner {
      padding-right: rem(4);
      position: relative;

      &::-webkit-scrollbar {
        margin-top: rem(20);
      }
    }
  }

  .option {
    padding: rem(17) rem(20);
    color: $text-color;
    font-size: rem(16);
    letter-spacing: 0;
    line-height: rem(19);
    text-align: left;
    position: relative;

    &:not(:last-of-type) {
      border-bottom: rem(1) solid $border-color;
    }
  }

  .option-list .option {
    font-size: rem(16);

    &:hover {
      border-radius: rem(10);
      cursor: pointer;
      background-color: rgba(232, 232, 243, 0.226);
    }
  }

  .selected-option {
    font-weight: 600;
  }
}

.dropdown-icon {
  //@include position-elevation(absolute, $top: rem(12), $right: rem(12), $elevation: 10);
  position: absolute;
  z-index: 1;
  width: rem(10);
  height: rem(10);
  top: rem(18);
  right: rem(17);
  :deep g path {
    fill: var(--blue_light_01);
  }
}

.selected-checkmark {
  position: absolute;
  right: rem(20);
  top: 50%;
  transform: translateY(-50%);
  height: rem(16);
}

.dropdown-icon.foldout {
  animation: rotateOpen .25s forwards;
}

.dropdown-icon:not(.foldout) {
  animation: rotateClosed .25s forwards;
}

.field-text {
  color: #989EB8;
  font-weight: 400;
  font-size: rem(16);
  margin-bottom: rem(16);
}

@keyframes rotateOpen {
  0% { rotate: 0deg }
  100% { rotate: 90deg }
}

@keyframes rotateClosed {
  0% { rotate: 90deg }
  100% { rotate: 0deg }
}

.dropdown-error {
  .basic-dropdown {
    border: 1px solid var(--red_error);
  }

  .dropdown-title, .dropdown-text {
    color: var(--red_error);
  }
}
</style>
